<template>
    <div class="travellist">
        <div class="tourism" v-for="(item,i) in goodlist" :key="i" @click="todetails(item.id)">
            <div class="img">
                <img :src="item.goods_pic" alt="" class="image">
                <div class="day">
                    <p class="d1"><span>{{item.t_days}}</span> 日</p>
                    <p class="d2">行程天数</p>
                </div>
                <div class="adress">{{item.origin_name}}出发</div>
                <div class="join">{{item.porder_count}}人已参加</div>
            </div>
            <div class="content">
                <h3 class="title">{{item.goods_name}}</h3>
                <div class="label">
                    <ul>
                        <li v-for="(it,j) in item.tags" :key="j">{{it}}</li>
                    </ul>
                </div>
                <div style="display: flex;color: #333333;font-size: 12px;margin-top: 10px;" v-if="item.place_address">

                        <p style="border:none">集合点:</p>
                        <p v-for="(it,j) in item.place_address.split(',')"
                         :key="j" style="border:none;margin-left: 10px;">{{it}}</p>
                </div>
                <div class="flex-between">
                    <div class="time">
											<div class="date-label"></div>
                        <div v-for="(tuan,j) in item.goods_price.slice(0,3)" class="tt" :key="j">
                            <p class="item-date">{{tuan.buy_date}}</p>
                            <p v-if="tuan.is_team" class="item-status orange">已成团</p>
                            <p v-else-if="tuan.remain_number === 0 && tuan.remain_alternate_number === 0" class="item-status orange">已满团</p>
                            <!-- <p v-if="tuan.is_alternate === 1 && tuan.remain_number === 0 && tuan.remain_alternate_number > 0" class="item-status orange">候补{{tuan.remain_alternate_number}}位</p>
                            <p v-if="tuan.remain_number > 0" class="item-status orange">余{{tuan.remain_number}}位</p> -->
                            <p class="item-status" v-else>可报名</p>
                        </div>


                    </div>
										<div class="price-item" v-if="item.price_type === 2">
											<span class="label">预售定金</span>
											<span class="val">{{item.common_price}}</span>
											<span class="val2">￥{{item.price}}</span>
										</div>
										<div class="price-item" v-else-if="item.price_type == 1">
											<span class="label">限时特价</span>
											<span class="val">{{item.common_price}}</span>
											<span class="val2">￥{{item.price}}</span>
										</div>
                    <p class="p" v-else>
                        <span>¥{{item.price | priceFilter}}</span> 起
                    </p>
                </div>

            </div>
        </div>
		 <div  class="nothing" v-if="goodlist.length==0">
			<img src="@/assets/nothing.png" alt=""/>
		</div>
        <!-- 分页 -->
			 <div id="page">
				<el-pagination
					layout="prev, pager, next"
					:total="total"
					@current-change="handleCurrentChange"
					:page-size="pageSize"
					>
				</el-pagination>
			</div>
    </div>
</template>

<script>
import {goodsListAPI} from "@/api/active"
import { mapState } from 'vuex'
export default {
  data () {
    return {

    }
    },
	props:{
		total:{
			type:Number
		},
		goodlist:{
			type:Array
		},
		pageSize:{
			type:Number
		}
	},
    created(){

  	},

  methods: {

	todetails(id){
		this.$router.push({ path: '/details',query:{id} })
	},
    handleCurrentChange: function(currentPage){
		this.$emit("handleCurrentChange",currentPage)
        // this.form.page=currentPage
        // this.getgoodsList()
    },
  }

}
</script>

<style lang="scss" scoped>
.travellist{
	background-color: #fff;
	border-radius: 10px;
	width:930px;
	min-height: 500px;
	margin-top: 20px;
	padding: 20px 20px;
	box-sizing: border-box;
	.tourism{
		display: flex;
		 margin-bottom: 20px;
		.img{
			position: relative;
			border-radius: 5px;
			width: 330px;
			height: 170px;
			overflow: hidden;
			.image{
				width: 330px;
				min-height: 170px;
				border-radius: 5px 5px 0 0;
			}
			.day{
				position: absolute;
				top: -1px;
				left: 0;
				width: 55px;
				background:rgba(0,0,0,0.4) url('../../assets/index/day.png') no-repeat;
				background-size: 100%;
				border-radius: 5px 0 0 0 ;
				p{
					color: #fff;
					text-align: center;
					font-size: 12px;
					line-height: 35px;
					span{
						color: #fff;
						font-weight: 500;
						font-size: 17px;
					}
				}
				.d2{
					line-height: 24px;
					font-size: 11px;
				}
			}

			.adress{
				position: absolute;
				bottom: 5px;
				left: 5px;
				padding: 5px 8px;
				font-size: 11px;
				color: #fff;
				background-color: rgba(0,0,0,0.4);
				border-radius:3px;
			}
			.join{
				position: absolute;
				bottom: 5px;
				right: 5px;
				padding: 5px 8px;
				font-size: 11px;
				color: #fff;
				background-color: rgba(0,0,0,0.4);
				border-radius:3px;
			}
		}

		.content{
			 width: 560px;
			padding-left: 20px;
			h3{
				font-size: 16px;
				font-weight: 600;
				line-height: 28px;
				height: 55px;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;				//溢出内容隐藏
				text-overflow: ellipsis;		//文本溢出部分用省略号表示
				display: -webkit-box;			//特别显示模式
				-webkit-line-clamp: 2;			//行数
				line-clamp: 2;
				-webkit-box-orient: vertical;	//盒子中内容竖直排列
			}
			.label{
				ul{
					display: flex;
					margin-top: 6px;
					li{
						border: 1px solid #ff7c00;
						border-radius: 2px;
						font-size: 12px;
						padding: 0 10px;
						color: #ff7c00;
						line-height: 23px;
						margin-right:15px ;
					}
				}
			}
			.flex-between{
				margin-top: 5px;
				.p{
					text-align: right;
					padding-right: 20px;
					width: 130px;
					color: #ff4c12;
					font-size: 12px;
					margin-top: 5px;
					span{
						font-size: 22px;
						font-weight: 600;
						text-align: left;
						color: #ff4c12;
					}
				}
				.time{
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					.date-label {
						width: 35px;
						height: 38px;
						background-repeat: no-repeat;
						background-size: 35px 38px;
						background-image: url('../../assets/date-label.png');
					}
					.tt {
						padding: 8px;
						margin-left: 8px;
						text-align: center;
						background: #F7F8FA;
						border-radius: 8px 8px 8px 8px;
					}
					.item-date {
						font-weight: bold;
						font-size: 16px;
						color: #1A1A1A;
						line-height: 20px;
						text-align: center;
						white-space: nowrap;
					}
					.item-status {
						font-size: 12px;
						color: #FA4C27;
						line-height: 14px;
						text-align: center;
					}
					.orange{
						color:#FF4C12
					}
					// >div{
					// 	width: 85px;
					// 	p{
					// 		font-size: 13px;

					// 		color: #666;
					// 		line-height: 25px;

					// 	}
					// 	.orange{
					// 		color:#FF4C12
					// 	}
					// }
				}
				.price-item {
					.label {
						font-weight: 500;
						font-size: 12px;
						color: #FA4C27;
						line-height: 17px;
					}
					.val {
						font-weight: bold;
						font-size: 20px;
						color: #FA4C27;
						line-height: 23px;
						margin-left: 6px;
						&::before {
							content: '￥';
							font-size: 10px;
						}
					}
					.val2 {
						font-weight: 500;
						font-size: 12px;
						color: #808080;
						line-height: 15px;
						margin-left: 4px;
					}
				}
			}

		}
	}
	.page{
		width: 120px;

		display: flex;
		height: 25px;
		margin: 45px auto 15px;
		p{

			margin: 0 8px;
			font-size: 14px;
			text-align: center;
			color: #333333;
			line-height: 25px;
		}
	}
}
.nothing{
	width: 100%;
	height: 700px;
	display: flex;
	justify-content: center;
	align-items: center;
	img{
		width: 500px;
	}
}
</style>
